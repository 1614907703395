<template>
  <div>
    <ValidationObserver ref="observer3"  v-slot="{ invalid }">
      <b-row class="justify-content-center">
        <b-col md="4" class="mb-2 mt-2 d-none d-lg-block"></b-col>
        <b-col md="4" class="mb-2 mt-2 text-left">
          <b-form-group label="* Gasto operativo mensual" class="text-center">
            <b-form-input
              @blur="currencyMonth(monthTotal)"
              class="form-control text-center"
              @keypress="onlyNumberWithDecimal"
              type="text"
              v-model="monthTotal"
            >
            </b-form-input>
            <br>
            <span class="text-center pt-1">Total: <strong>{{ TotalCost }}</strong></span>
          </b-form-group>
        </b-col>
        <b-col md="4" class="mb-2 mt-2">
          <b-button
            v-if="isEdit && can('fivesclub_profit_update_income_expenses')"
            variant="primary"
            v-b-tooltip.hover
            title="Guardar"
            @click="saveExpenses"
            :disabled="invalid"
            class="btn btn-success  marginButtonForms"
          >
            <b-spinner small v-if="isSavingExpenses" /> Actualizar
          </b-button>
            <b-button
            v-if="!isEdit && can('fivesclub_profit_save_income_expenses')"
            variant="primary"
            v-b-tooltip.hover
            title="Guardar"
            @click="saveExpenses"
            :disabled="invalid"
            class="btn btn-success  marginButtonForms"
          >
            <b-spinner small v-if="isSavingExpenses" /> Guardar
          </b-button>
        </b-col>
      </b-row>
      <!-- <hr class="mt-0 mb-3"> -->
      <b-row class="justify-content-center">
        <b-col
          md="4"
          class="table-responsive"
          v-for="listDays in daysList.forDaysMontsGroup"
          :key="listDays.day"
        >
          <table class="table">
            <thead class="text-center">
              <tr>
                <th scope="col">Fecha</th>
                <th scope="col">Ingreso diario</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="days in listDays" :key="days.day">
                <td scope="row">{{ days.dateIncome }}</td>
                <td>
                  <ValidationProvider rules="required" :name="`ingreso ${days.dateIncome}`">
                    <b-form-group slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        placeholder="0.00"
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="days.dailyIncome"
                        @blur="currency(days)"
                        @keypress="onlyNumberWithDecimal"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { utils } from "@/modules/fivesClub/mixins/utils";
import { numberWithCommas,daysMonthsAll,toCurrency } from "@/helpers/helpers";
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [utils, acl ],

  props: {
    daysList: {
      type: Object,
    },
    parameterInfo: {
      type: Object ,
    },
  },
  data() {
    return {
      monthTotal: "",
      isSavingExpenses:false,
      isEdit:false,
      costsAreBlocked:false
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("fivesClubProfit", ["expenses"]),
    TotalCost(){
      return toCurrency(this.expenses?.totalCost || 0.00)
    }
  },
  watch: {
    daysList:{
      immediate: true,
      handler() {
        if (this.expenses.length!=0) {
          this.isEdit=true
          this.monthTotal=this.expenses.monthlyCost
        } else this.isEdit=false

        this.costsAreBlocked=this.expenses?.incomeRentalStatus?.blocked
      }
    },
  },
  methods: {
    ...mapActions("fivesClubProfit", ['saveExpensesList','updatUxpensesList']),
    currency(data) {
      data.dailyIncome = numberWithCommas(data.dailyIncome);
    },
    currencyMonth(data) {
      this.monthTotal = numberWithCommas(data);
    },

    async saveExpenses() {
      let payload;
      let daysList = daysMonthsAll(this.daysList.forDaysMontsGroup)
      payload = {
        parameters: this.parameterInfo,
        daysList: daysList,
        monthTotal: this.monthTotal.split(",").join(""),
        userId: this.user.idUser,
      };

      this.isSavingExpenses=true
      this.isEdit ?  await this.updatUxpensesList(payload): await this.saveExpensesList(payload);
      this.isSavingExpenses=false

    },
  },
};
</script>

<style lang="scss" >
.display-6 {
  font-size: 13px;
}
.marginButtonForms{
  margin: 27px 0 0 0;
}
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px!important;
  }
  .table-responsive th div {
    font-size: 10px!important;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 11px!important;
  }
  .buttonInfo button {
    padding: 9px;
  }
  .buttonInfo {
    display: table-cell;
  }
  .isCentered {
    align-content: center;
    align-items: center;
  }
  .isSpinner {
    padding: 2rem;
  }
}
</style>
