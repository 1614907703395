<template>
  <div class="list-filters">
    <h4>Filtros</h4>

    <validation-observer v-slot="{ invalid }" ref="searchDetailTransactions">
      <b-form @submit.prevent="getExpenses">
        <b-row class="" align-v="center">
          <!-- Tipo de Busqueda -->
          <b-col md="3">
            <validation-provider name="tipo Busqueda" rules="required">
              <b-form-group label="Pool de renta">
                <b-form-select v-model="rentalPool" placeholder="Pool">
                  <option selected value="">Seleccione un Pool de renta</option>
                  <option
                    v-for="condo in rentalPoolFilter"
                    :key="condo.id"
                    :value="condo.id"
                  >
                    {{ condo.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2" lg="3">
            <b-form-group label="Año">
              <b-form-select v-model="year" :options="validYears" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Mes">
              <b-form-select v-model="month" :options="monthList" />
            </b-form-group>
          </b-col>
          <!-- Botones -->
          <b-col md="2" sm="6" class="text-nowrap mt-1">
            <b-button
              class="mr-1"
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="invalid || isFetchExpenses"
            >
              <b-spinner small v-if="isFetchExpenses" /> Buscar
            </b-button>

            <b-button
              class="btn fives-club-excel-btn mr-1"
              type="button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              :disabled="invalid || isLoadingExcel"
              @click.prevent="getExpensesExcel"
            > 
              <b-spinner small v-if="isLoadingExcel" />
              <feather-icon icon="FileTextIcon" /> Excel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { utils } from "@/modules/fivesClub/mixins/utils";
import { monthList } from "@/data/data";

export default {
  directives: {
    Ripple,
  },
  mixins: [utils],
  props: {
    isFetchExpenses: {
      type: Boolean,
      required: true,
    },
    isLoadingExcel: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    vSelect,
  },

  data() {
    return {
      isFindExpenses: false,
      rentalPool: "",
      monthList,
      datenow: new Date(),
      year: new Date().getFullYear(),
      month: 1,
    };
  },
  computed: {
    ...mapState("fivesClubProfit", ["rentalPoolFilter"]),
    validYears() {
      const years = []
      const currentYear = new Date().getFullYear()
      for (let i = currentYear - 2; i < currentYear + 1; i++) {
        years.push(i + 1)
      }
      return years
    },
  },
  methods: {
    ...mapActions("fivesClubProfit", ["fetchExcelProfitCost"]),

    getExpenses() {
      let payload;
      payload = {
        rentalPoolId: this.rentalPool,
        year: this.year,
        month: this.month,
      };
      this.$emit("find-day", payload);
    },
    getExpensesExcel() {
      const payload = {
        rentalPoolId: this.rentalPool,
        year: this.year,
        month: this.month,
        getExcel: true
      };
      this.$emit("find-day", payload);
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}
.filters {
  padding-bottom: 2rem;
}
.paddler {
  padding-left: 1rem;
}

.filters {
  border: solid 1px #b8c2cc;
  padding: 12px 12px 13px 12px !important;
  margin: 0 0 16px 0;
}
.marginButtonForms {
  margin: 27px 0 0 0;
}
</style>